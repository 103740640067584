import axios, { AxiosInstance } from "axios";
import {
  FinalizeMintResponse,
  GenerateNFTNoWaitResponse,
  GenerateNFTResponse,
  GetCreationsResponse,
  LoginResponse,
} from "./api.type";

class ApiService {
  // eslint-disable-next-line no-use-before-define
  private static sInstance: ApiService;
  gateway: AxiosInstance;

  constructor() {
    this.gateway = axios.create({
      timeout: 60000,
      headers: { "Content-Type": "application/json" },
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    this.gateway.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        // const originalRequest = error.config;
        // if (error.response.status === 403 && !originalRequest._retry) {
        //   originalRequest._retry = true;
        //   const access_token = await refreshAccessToken();
        //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        //   return axiosApiInstance(originalRequest);
        // }
        return Promise.reject(error);
      }
    );

    this.gateway.interceptors.request.use(
      (config) => {
        const token = window.localStorage.getItem("token");
        if (config.headers && token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  public static get instance(): ApiService {
    if (!ApiService.sInstance) {
      ApiService.sInstance = new ApiService();
    }

    return ApiService.sInstance;
  }

  async login(data: {
    instruction: string;
    signature: string;
    pubkey: string;
  }) {
      console.log(data);
    return this.gateway.post<any, LoginResponse>("/login", data);
  }

  async generateNFT(data: { prompt: string; aitype: string }) {
    return this.gateway.post<any, GenerateNFTResponse>("/generate", data, {
      timeout: 5 * 60 * 1000, // 5 minutes
    });
  }

  async generateNFTNoWait(data: {
    prompt: string;
    aitype: string;
    cognitoid: string;
  }) {
    return this.gateway.post<any, GenerateNFTNoWaitResponse>(
      "/generate_nowait",
      data
    );
  }

  async finalizeMint(data: {
    imageid?: string;
    txid?: string;
    name: string;
    website: string;
    description: string;
    network: string;
  }) {
    return this.gateway.post<any, FinalizeMintResponse>("/finalize", data);
  }

  async updateNFT(data: {
    tokenid: string;
    name: string;
    website: string;
    description: string;
    network: string;
    collection: string;
  }) {
    return this.gateway.post<any, any>("/update", data);
  }

  async getCreations() {
    const data = await this.gateway.get<any, GetCreationsResponse>(
      "/creations",
      { params: { network: process.env.REACT_APP_SOLANA_NETWORK } }
    );
    return data.map((d) => ({
      ...d,
      uri: d.imageurl,
    }));
  }
}

export default ApiService;
