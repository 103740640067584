import * as anchor from "@project-serum/anchor";

export const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!
    );

    return candyMachineId;
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

export function makeId(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function unicodeDecodeB64(str: string) {
  try {
    const base64ToString = decodeURIComponent(
      Buffer.from(str, "base64").toString()
    );
    return JSON.parse(base64ToString);
  } catch (e) {
    return null;
  }
}

export async function checkTokenExpire() {
  const token = await window.localStorage.getItem("token");
  if (token) {
    const payloads = token.split(".");
    const data = unicodeDecodeB64(payloads[1] || "");
    return data?.expires > Date.now() / 1000;
  }
  return false;
}

export async function verifyTokenExpiration() {
  const isValid = await checkTokenExpire();
  if (!isValid) {
    throw new Error("TOKEN_EXPIRED");
  }
}
