import { createContext, ReactNode } from "react";
import * as anchor from "@project-serum/anchor";
import { CandyMachineAccount, getCandyMachineState } from "utils/candy-machine";
import { getCandyMachineId } from "utils/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import ClipLoader from "react-spinners/ClipLoader";

export const CandyMachineContext = createContext<{
  candyMachine?: CandyMachineAccount;
  candyMachineId?: anchor.web3.PublicKey | undefined;
}>({});

const candyMachineId = getCandyMachineId();
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
);

type CandyMachineProviderProps = {
  children: ReactNode;
};
export const CandyMachineProvider = ({
  children,
}: CandyMachineProviderProps) => {
  const wallet = useWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as typeof anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          candyMachineId,
          connection
        );
        setCandyMachine(cndy);
      } catch (e) {
        console.log("There was a problem fetching Candy Machine state");
        console.log(e);
      }
    }
  }, [anchorWallet]);

  useEffect(() => {
    refreshCandyMachineState();
  }, [anchorWallet, refreshCandyMachineState]);

  return (
    <CandyMachineContext.Provider
      value={{
        candyMachine,
        candyMachineId,
      }}
    >
      {children}
    </CandyMachineContext.Provider>
  );
};
