import { useState, useEffect } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletModalButton } from "@solana/wallet-adapter-react-ui";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";

import useStores from "hooks/use-stores";
import { ReactComponent as StarSvg } from "assets/icons/star.svg";
import MintButton from "components/mint-button";
import {
    awaitTransactionSignatureConfirmation,
    mintOneToken,
} from "utils/candy-machine";
import useCandyMachine from "hooks/use-candy-machine";
import { TX_TIMEOUT_IN_MILLISECONDS } from "constants/index";
import { autorun } from "mobx";
import { verifyTokenExpiration } from "utils/helpers";
import './wish.css';

interface Collection {
    collection: string;
    selector_name: string;
    concept: string;
    chain: string;
}

const Wish = () => {


    const [collections, setCollections] = useState<Collection[]>([]);


    const [layout, setLayout] = useState("default");
    const wallet = useWallet();
    const { candyMachine } = useCandyMachine();
    const {
	nftStore: {
	    generateNFT,
	    finalizeMint,
	    updateNFT,
	    setCurrentNFT,
	    currentNFT,
	},
	authStore: { login, isMqttConnected },
    } = useStores();
    const { nftStore } = useStores();
    const [isMint, setIsMint] = useState(false);
    const [isUserMinting, setIsUserMinting] = useState(false);
    const { connection } = useConnection();

    useEffect(() => {
	const fetchData = async () => {
	    try {
		const response = await fetch(
		    "https://ai-nftgenie.s3.us-west-2.amazonaws.com/nftgenie_concepts.json"
		);
		const data = await response.json();
		console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
		console.log(data);
		console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
		setCollections(data);
		setFilteredCollections(data); 
	    } catch (error) {
		console.error("Error fetching data:", error);
	    }
	};

	fetchData();
    }, []);

    
    useEffect(() => {
	autorun(() => {
	    if (nftStore.generateNFTStatus === "finished") {
		setLayout("congrats");
		nftStore.updateGenerateNFTStatus(undefined);
	    }
	});
    });

    const handleGenerateMint = async (values: any) => {
	try {
	    await verifyTokenExpiration();
	    await generateNFT(values);
	} catch (e: any) {
	    let message = e.message;
	    if (e.message === "TOKEN_EXPIRED") {
		message = "Token expired. Please re-approve login and try again.";
		login(wallet);
	    }
	    toast.error(message, {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	    });
	}
    };

    const onMint = async () => {
	try {
	    await verifyTokenExpiration();

	    setIsUserMinting(true);
	    document.getElementById("#identity")?.click();
	    if (wallet.connected && candyMachine?.program && wallet.publicKey) {
		const token = await mintOneToken(candyMachine, wallet.publicKey);
		const mintTxId = token[0];

		let status: any = { err: true };
		if (mintTxId) {
		    status = await awaitTransactionSignatureConfirmation(
			mintTxId,
			TX_TIMEOUT_IN_MILLISECONDS,
			connection,
			true
		    );
		}

		if (status && !status.err) {
		    await finalizeMint({
			txId: mintTxId,
			mint: currentNFT!,
		    });
		    formik2.setFieldValue("nftName", currentNFT?.prompt);
		    setIsMint(true);

		    toast.success("Congratulations! Mint succeeded!", {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		    });
		} else {
		    toast.error("Mint failed! Please try again!", {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		    });
		}
	    }
	} catch (error: any) {
	    let message;
	    if (error.message === "TOKEN_EXPIRED") {
		message = "Token expired. Please re-approve login and try again.";
	    } else {
		message = error.msg || "Minting failed! Please try again!";
		if (!error.msg) {
		    if (!error.message) {
			message = "Transaction Timeout! Please try again.";
		    } else if (error.message.indexOf("0x137")) {
			message = `SOLD OUT!`;
			setCurrentNFT(undefined);
			setLayout("default");
			formik1.resetForm();
		    } else if (error.message.indexOf("0x135")) {
			message = `Insufficient funds to mint. Please fund your wallet.`;
		    }
		} else {
		    if (error.code === 311) {
			message = `SOLD OUT!`;
			setCurrentNFT(undefined);
			setLayout("default");
			formik1.resetForm();
		    } else if (error.code === 312) {
			message = `Minting period hasn't started yet.`;
		    }
		}
	    }

	    toast.error(message, {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	    });
	} finally {
	    setIsUserMinting(false);
	}
    };

    const handleUpdateMintMetadata = async (values: {
	nftName: string;
	nftDescription: string;
	nftWebsite: string;
    }) => {
	try {
	    await verifyTokenExpiration();
	    await updateNFT({
		tokenid: currentNFT?.tokenid!,
		name: values.nftName,
		website: values.nftWebsite,
		description: values.nftDescription,
	    });
	    setLayout("success");
	} catch (e: any) {
	    let message = e.message;
	    if (e.message === "TOKEN_EXPIRED") {
		message = "Token expired. Please re-approve login and try again.";
		login(wallet);
	    }
	    toast.error(message, {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	    });
	}
    };

    const handleGenerateMintAgain = () => {
	setLayout("default");
	setCurrentNFT(undefined);
    };

    const handleGenerateAgain = () => {
	setLayout("default");
	formik1.resetForm();
	setCurrentNFT(undefined);
    };

    const renderSuccessSection = () => {
	return (
	    <>
		<section className="text-center flex flex-col items-center mb-12">
		<h3 className="text-blue font-extrabold text-sm">
		CREATE INSPIRING ART WITH OUR AI GENIE
            </h3>
		<h1 className="font-extrabold text-7xl tracking-tighter leading-snug">
		<span className="text-linear">Success</span>
		<span className="relative">
		<StarSvg className="inline-block w-6 absolute top-0" />
		</span>
		</h1>
		<h5 className="w-1/2 mt-2 text-grayLight">
		The Genie has generated your art into a NFT
            </h5>
		</section>
		<section className="mt-25 flex flex-col items-center mb-80">
		<div className="bg-blackLight py-10 px-12 w-[60%]">
		<div className="flex gap-x-10 items-center">
		<div className="w-56 h-56 rounded-lg">
                <img
            src={currentNFT?.uri}
            alt={currentNFT?.name}
            className="w-full h-full object-fill"
                />
		</div>
		<div className="flex-1">
                <div className="text-gray text-xs">NFT Name</div>
                <div className="text-lg font-bold">{currentNFT?.name}</div>
                <div className="text-gray text-xs mt-7">NFT Description</div>
                <div className="text-lg font-bold">
                {currentNFT?.description}
            </div>
		</div>
		</div>
		</div>
		<div className="mt-10 text-center">
		<a
            className="btn-linear py-4 px-8 font-extrabold mr-10 shadow-button"
            href={`https://twitter.com/intent/tweet?text=${currentNFT?.uri}`}
		>
		Share On Twitter
            </a>
		<button
            className="bg-blackLight py-4 px-8 font-extrabold border border-white border-solid rounded-lg shadow-button"
            onClick={handleGenerateAgain}
		>
		Generate again
            </button>
		</div>
		</section>
		</>
	);
    };

    const formik1 = useFormik({
	initialValues: {
	    prompt: "",
	    aitype: "phoebe",
	},
	onSubmit: handleGenerateMint,
    });
    

    const [concept, setConcept] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [filteredCollections, setFilteredCollections] = useState<Collection[]>([]);

    

    const handleConceptChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
	const selectedConcept = e.target.value;
	const selectedConceptObj = collections.find(
	    (collection) => collection.selector_name === selectedConcept
	);
	setConcept(selectedConceptObj?.selector_name || '');

	formik1.setFieldValue("aitype", selectedConceptObj?.concept);

	setInputValue((prevValue) => {
	    if (concept && prevValue.includes(concept)) {
		const conceptIndex = prevValue.indexOf(concept || '');
		const leftText = prevValue.slice(0, conceptIndex);
		const rightText = prevValue.slice(conceptIndex + (concept?.length || 0));

		formik1.setFieldValue('prompt', `${leftText}${selectedConcept}${rightText}`);
		return `${leftText}${selectedConcept}${rightText}`;
	    } else {
		formik1.setFieldValue('prompt', `${prevValue}${selectedConcept}`);
		return `${prevValue}${selectedConcept}`;
	    }
	});
    };
    
    

    const filterByChain = (chain: string) => {
	setFilteredCollections(collections.filter((c) => c.chain === chain));
    };
    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	const newValue = e.target.value;

	// Update the condition to handle the case when concept is null
	if (concept === null || newValue.includes(concept)) {
	    setInputValue(newValue);
	    formik1.setFieldValue('prompt', newValue);
	}
    };
    

    const selectStyle = {
	backgroundColor: 'white',
	color: 'black',
	padding: '4px',
	borderRadius: '4px',
    };

    
    const inputStyle = {
	backgroundColor: 'white',
	color: 'black',
	padding: '4px',
	borderRadius: '4px',
	width: '100%',
    };


    const formik2 = useFormik({
	initialValues: {
	    nftName: "",
	    nftDescription: "",
	    nftWebsite: "https://nftgenie.ai",
	},
	validationSchema: yup.object({
	    nftName: yup.string().required("NFT Name is required"),
	    nftDescription: yup.string().optional(),
	    nftWebsite: yup.string().optional(),
	}),
	onSubmit: handleUpdateMintMetadata,
    });

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
	if (e.key === "✯") {
	    e.preventDefault();
	}
    };


    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
	e.preventDefault();
	const pastedText = e.clipboardData.getData("text");
	const filteredText = pastedText.replace(/✯/g, "");
	const target = e.target as HTMLInputElement;
	const selectionStart = target.selectionStart ?? 0;
	const selectionEnd = target.selectionEnd ?? 0;
	const newValue = inputValue.slice(0, selectionStart) + filteredText + inputValue.slice(selectionEnd);
	setInputValue(newValue);
	formik1.setFieldValue("prompt", newValue);
    };

    	    // 	<div className="chain-filter">
	    // 	<img
	    // src="solana-dummy-image.png"
	    // alt="Solana"
	    // onClick={() => filterByChain("solana")}
	    // 	/>
	    // 	<img
	    // src="ethereum-dummy-image.png"
	    // alt="Ethereum"
	    // onClick={() => filterByChain("ethereum")}
	    // 	/>
	    // 	</div>

    const renderDefaultSection = () => {
	const isFormSubmitting =
	    formik1.isSubmitting || nftStore.generateNFTStatus === "processing";
	return (
	    <>
		<section className="max-w-screen-xxl px-20 text-center flex flex-col items-center mb-12">
		<h3 className="text-blue font-extrabold text-sm">
		CREATE INSPIRING ART WITH OUR AI GENIE
            </h3>
		<h1 className="font-extrabold text-7xl tracking-tighter leading-snug">
		Ask the Genie
		<span className="relative">
		<StarSvg className="inline-block w-6 absolute top-0" />
		</span>
		</h1>
		</section>

		{!wallet?.connected && (
		    <section className="mt-52">
			<h2 className="font-extrabold text-5xl">Connect your wallet</h2>
			<h5 className="text-gray text-lg w-1/3">
			Your crypto wallet holding a Parasite NFT (SOL Parasite or ETH Parsite) has to be connected in order to use the NFT Genie.
			<br />
			</h5>
			<WalletModalButton className="wallet-adapter-button-trigger btn-linear py-4 px-8 font-extrabold mt-5">
			Connect Wallet
		    </WalletModalButton>
			</section>
		)}

		<section className="mt-25 mb-50">
		<div className="flex flex-1 items-center">
		<form onSubmit={formik1.handleSubmit}>
		<h2 className="font-extrabold text-5xl">Create your art</h2>
		<h5 className="text-gray text-lg">
                Enter a text and the Genie will fulfill what you wish for.
		</h5>
	        <div>
		<label htmlFor="conceptSelector">Select NFT Collection: </label>


		<select
  id="conceptSelector"
  value={concept || ''}
  onChange={handleConceptChange}
  style={selectStyle}
>
  <option key="phoebe" value="">no nft selected</option>
  {filteredCollections.map((collection: Collection) => (
    <option key={collection.concept} value={collection.selector_name}>
      {collection.collection}
    </option>
  ))}
</select>
		
		</div>
		<input
	    className="rounded bg-whiteA10 h-16 w-full px-5 mt-8"
	    id="inputField"
	    value={inputValue}
	    onChange={handleInputChange}
	    onKeyPress={handleKeyPress}
	    onPaste={handlePaste}
		/>
		<div className="h-8 pt-2">
                <div className="text-red-500 text-sm">
                {formik1.touched.prompt && Boolean(formik1.errors.prompt)
                    ? formik1.touched.prompt && formik1.errors.prompt
                    : undefined}
            </div>
		</div>
		<div className="flex gap-x-10">
		</div>

		<div className="h-10 pt-2">
                <div className="text-red-500 text-sm">
                {formik1.touched.aitype && Boolean(formik1.errors.aitype)
                    ? formik1.touched.aitype && formik1.errors.aitype
                    : undefined}
            </div>
		</div>
		<div className="flex items-center">
                <button
	      className={`border rounded-lg border-solid text-grayLight h-15 w-48 font-extrabold text-lg mr-5 ${
    isFormSubmitting || !isMqttConnected || !concept || concept === ""
      ? "bg-grayDark"
      : "wallet-adapter-button-trigger btn-linear"
  }`}
	    disabled={isFormSubmitting || !isMqttConnected || !concept || concept === ""}
            type="submit"
                >
                Generate
            {isFormSubmitting && (
                <span className="ml-2">
                    <ClipLoader
                color="rgba(201, 201, 201, 1)"
                loading
                size={15}
                    />
                    </span>
            )}
            </button>
		</div>
		</form>
		<div className="flex flex-1 justify-center items-center">
		<div className="text-center border border-solid border-grey bg-whiteA10 rounded h-[30rem] w-[30rem] flex justify-center items-center">
                {isFormSubmitting ? (
                    <div>
			<ClipLoader color="#813eee" loading size={60} />
			<p className="mt-5 font-extrabold text-2xl">
			Generating your wish...
			</p>
			</div>
                ) : (
                    currentNFT && (
			<img
			src={currentNFT.uri}
			alt="mint"
			className="w-full h-full"
			    />
                    )
                )}
            </div>
		</div>
		</div>
		</section>
		</>
	);
    };
    
    const renderCongratsSection = () => {
	return (
	    <>
		<section className="max-w-screen-xxl px-20 text-center flex flex-col items-center mb-12">
		<h3 className="text-blue font-extrabold text-sm">
		CREATE INSPIRING ART WITH OUR AI GENIE
            </h3>
		<h1 className="font-extrabold text-7xl tracking-tighter leading-snug">
		Congratz!
		<span className="relative">
		<StarSvg className="inline-block w-6 absolute top-0" />
		</span>
		</h1>
		<h5 className="w-1/2 mt-2 text-grayLight">
		The Genie has fulfilled your wish.<br/>
		</h5>
		</section>
		<section className="mt-25 mb-50">
		<div className="flex">
		<div className="flex flex-1 items-center">
                <div>
                <div className="text-sm text-gray">Text Prompt</div>
                <div className="text-lg font-bold">{currentNFT?.prompt}</div>
                <div className="flex items-center mt-14">
                <button
            className="border rounded-lg border-solid bg-grayDark h-15 w-48 font-extrabold text-lg shadow-button"
            onClick={handleGenerateMintAgain}
            disabled={isUserMinting}
                >
                Generate again
            </button>
                </div>
                </div>
		</div>
		<div className="flex flex-1 justify-center items-center">
		<div className="text-center border border-solid border-grey bg-whiteA10 rounded h-[25rem] w-[25rem] flex justify-center items-center">
                <img
            src={currentNFT?.uri}
            alt="mint"
            className="w-full h-full"
                />
		</div>
		</div>
		</div>
		</section>
		</>
	);
    };

    const renderContent = () => {
	// return <WaitingPlaceholder />;
	switch (layout) {
	    case "congrats": {
		return renderCongratsSection();
	    }
	    case "success": {
		return renderSuccessSection();
	    }
	    default: {
		return renderDefaultSection();
	    }
	}
    };

    return (
	<div className="max-w-screen-xxl mx-auto pt-52 px-20">
	    {renderContent()}
	</div>
    );
};

export default Wish;
