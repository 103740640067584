import { Route, Routes } from "react-router-dom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { useEffect, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import Layout from "containers/layout";
import Wish from "pages/wish";
import Home from "pages/home";
import FAQ from "pages/faq";
//import YourCreations from "pages/your-creations";

// Default styles that can be overridden by your app
import "react-toastify/dist/ReactToastify.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import { CandyMachineProvider } from "contexts/candy-machine";
import { observer } from "mobx-react";
import useStores from "hooks/use-stores";

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const App = () => {
  const {
    authStore: { setupCognito },
  } = useStores();
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  // const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );

  useEffect(() => {
    setupCognito();
  }, [setupCognito]);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <CandyMachineProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="wish" element={<Wish />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="*" element={<Home />} />
              </Routes>
              <ToastContainer />
            </Layout>
          </CandyMachineProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default observer(App);
