import { useRef, useState, ReactNode } from "react";
import { ReactComponent as ArrowDownSvg } from "assets/icons/arrow-down.svg";

interface AccordionProps {
  title: ReactNode;
  content: ReactNode;
}

const Accordion = ({ title, content }: AccordionProps) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState("transform duration-700 ease");

  const contentSpace = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setActive(active === false ? true : false);
    setHeight(active ? "0px" : `${contentSpace.current?.scrollHeight}px`);
    setRotate(
      active
        ? "transform duration-700 ease"
        : "transform duration-700 ease rotate-180"
    );
  };

  return (
    <div className="flex flex-col mb-15">
      <button
        className="bg-blackLight py-7 px-10 box-border appearance-none cursor-pointer focus:outline-none border border-grayLight rounded-lg hide-scroll-bar"
        onClick={toggleAccordion}
      >
        <div className="flex items-center justify-between">
          <p className="inline-block font-bold text-grey text-2xl">{title}</p>
          <ArrowDownSvg className={`${rotate} inline-block`} />
        </div>
        <div
          ref={contentSpace}
          style={{ maxHeight: `${height}` }}
          className="overflow-auto transition-max-height duration-700 ease-in-out hide-scroll-bar"
        >
          <div className="pt-4 text-gray text-left border-t border-grayA10 mt-4">
            {content}
          </div>
        </div>
      </button>
    </div>
  );
};

export default Accordion;
