import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";
import { configurePersistable } from "mobx-persist-store";
import AWS from "aws-sdk";

import App from "pages/app";
import reportWebVitals from "./reportWebVitals";
import "styles/index.css";

AWS.config.update({ region: "us-west-2" });
configure({
  // useProxies: 'never',
  // enforceActions: 'always',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  disableErrorBoundaries: false,
});
configurePersistable({
  debugMode: false,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
