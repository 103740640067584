import { Link, useLocation } from "react-router-dom";
import { WalletModalButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import { ReactComponent as CavetDownSvg } from "assets/icons/arrow-down.svg";
import { ReactComponent as LogoSvg } from "assets/icons/logo.svg";
import { ReactComponent as DiscordSvg } from "assets/icons/discord.svg";
import { ReactComponent as TwitterSvg } from "assets/icons/twitter.svg";
import useStores from "hooks/use-stores";
import { toast } from "react-toastify";

type NavBarProps = {};
const NavBar = (props: NavBarProps) => {
  const { pathname } = useLocation();
  const { connected, publicKey, signMessage } = useWallet();
  const { authStore } = useStores();

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
  const content = useMemo(() => {
    if (!base58) return null;
    return base58.slice(0, 4) + ".." + base58.slice(-4);
  }, [base58]);

  useEffect(() => {
    async function doLogin() {
      try {
        await authStore.login({ publicKey, signMessage });
      } catch (err: any) {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (connected && !authStore.token) {
      doLogin();
    }
  }, [authStore, connected, publicKey, signMessage]);

  const handleCopyAddress = useCallback(async () => {
    if (base58) {
      await navigator.clipboard.writeText(base58);
      toast.success("Wallet address copied", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [base58]);

  const handleDisconnect = () => {
    authStore.disconnect();
  };

  return (
    <nav className="absolute top-11 w-full">
      <div className="max-w-screen-xxl mx-auto h-full">
        <div className="flex justify-between px-20 items-start h-full">
          <Link to="/">
            <LogoSvg className="h-20" />
          </Link>
          <div>
            <div className="flex gap-x-10 font-bold text-lg xl:gap-x-20">
              <Link
                to="/wish"
                className={`p-3 ${
                  pathname === "/wish"
                    ? "border-b-2 border-pink border-solid text-pink"
                    : ""
                }`}
              >
                Wish
              </Link>
              <Link
                to="/faq"
                className={`p-3 ${
                  pathname === "/faq"
                    ? "border-b-2 border-pink border-solid text-pink"
                    : ""
                }`}
              >
                FAQ
              </Link>
            </div>
          </div>
          <div className="flex items-start gap-x-10">
            <a
              href="https://discord.gg/uWxfT8FMs8"
              target="_blank"
              rel="noreferrer"
              className="hidden xl:inline-block mt-2"
            >
              <DiscordSvg />
            </a>
            <a
              href="https://twitter.com/NFTGenieAI"
              target="_blank"
              rel="noreferrer"
              className="hidden xl:inline-block mt-2"
            >
              <TwitterSvg />
            </a>
            {connected ? (
              <div className="group rounded bg-linear border-solid border border-transparent">
                <div className="bg-blackLight py-4 font-medium text-lg rounded text-white">
                  <div className="flex items-center px-8">
                    <button onClick={handleCopyAddress}>{content}</button>
                    <span className="ml-4 cursor-pointer">
                      <CavetDownSvg />
                    </span>
                  </div>
                  <div className={`w-full mt-4 px-2 hidden group-hover:block`}>
                    <button
                      className="bg-black py-5 text-center w-full rounded-lg"
                      onClick={handleDisconnect}
                    >
                      Disconnect
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <WalletModalButton className="wallet-adapter-button-trigger btn-linear py-4 px-6 font-extrabold">
                Connect Wallet
              </WalletModalButton>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default observer(NavBar);
