import { ReactComponent as StarSvg } from "assets/icons/star.svg";
import Accordion from "components/accordion";

const FAQ = () => {
return (
<div className="max-w-screen-xxl mx-auto pt-52 px-40">
<section className="text-center flex flex-col items-center mb-12">
<h3 className="text-blue font-extrabold text-sm">
CREATE INSPIRING ART WITH OUR AI GENIE
</h3>
<h1 className="font-extrabold text-7xl tracking-tighter leading-snug">
FAQ
<span className="relative">
<StarSvg className="inline-block w-6 absolute top-0" />
</span>
</h1>
<h5 className="w-1/2 mt-2 text-grayLight">
If you can't find an answer here, the best way is to check out our Discord community to get your questions answered.
</h5>
</section>
<section className="mt-20 mb-36">
<div>
<Accordion
         title="What is the background of the NFT Genie project?"
         content="NFT Genie was an AI Art website that allowed you to mint AI art creations as NFTs. The major part of the proceedings went to SOL Parasite holders who have staked their Parsite NFTs. The service is discontinued and has been relaunched as an exclusive AI Utility website for Parasite NFT holders only (both ETH Parasites and SOL Parasites holders)."
       />
<Accordion
         title="What are the unique capabilities of NFT Genie?"
         content="Unlike other popular text-to-image AI websites, NFT Genie comes with a unique capability. It knows your NFTs (not your NFTs but basically all popular NFT collections). So you can create community art for your favorite project or memes for your favorite project."
       />
<Accordion
         title="Who can access the new NFT Genie?"
         content="Only Parasite holders will have access to the new NFT Genie."
       />
<Accordion
         title="How do I become a Parasite holder?"
         content="To become a Parasite holder, you need to own either an ETH Parasite or a SOL Parasite NFT. You can acquire these on the respective primary marketplaces or secondary markets like OpenSea and Magic Eden."
       />
</div>
</section>
</div>
);
};

export default FAQ;