import ReactPlayer from "react-player";
import Slider from "react-slick";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as StarSvg } from "assets/icons/star.svg";
import { ReactComponent as ArrowLeftSvg } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightSvg } from "assets/icons/arrow-right.svg";
import Ball from "assets/images/video_placholder.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Mints = [
    {
	id: 1,
	url: require("assets/images/mint_1.png"),
	title: "Lucid Dreamscape",
	prompt: "lucid dreamscape",
    },
    {
	id: 2,
	url: require("assets/images/mint_2.png"),
	title: "Giant Turtle falling into a Black Hole",
	prompt: "giant turtle floating into a black hole",
    },
    {
	id: 3,
        url: require("assets/images/mint_5.png"),
	title: "The Ape",
	prompt: "monkey skull art",

    },
    {
	id: 4,
	url: require("assets/images/mint_4.png"),
	title: "The Open Sea",
	prompt: "Cthulhu ship wreck",
    },
    {
	id: 5,
        url: require("assets/images/mint_3.png"),
	title: "Arabian Nights",
	prompt: "arabian nights landscape painting",
    },
];

const videoimg = require("assets/images/ball.png");

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
};

const Home = () => {
  const navigate = useNavigate();
  const sliderRef = useRef<Slider>(null);
          // <ReactPlayer
          //       url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          //       width="29rem"
          //       height="25rem"
          //       style={{
          //         margin: "0 auto",
          //       }}
          //     />


  return (
    <div className="pt-52">
      <section className="max-w-screen-xxl mx-auto px-20 text-center flex flex-col items-center mb-12">
        <h3 className="text-blue font-extrabold text-sm">
          CREATE INSPIRING ART WITH NFT GENIE
        </h3>
        <h1 className="font-extrabold text-7xl tracking-tighter">
          Make a nft <span className="text-linear">wish</span>{" "}
          <span className="relative">
            <StarSvg className="inline-block w-6 absolute top-0" />
          </span>
        </h1>
        <h5 className="w-1/2 mt-2 text-gray">
          Unleash Your Imagination with NFT Genie: The AI-Powered Art Generator Fulfilling Your Wishes.
        </h5>
        <button
          className="btn-linear py-4 px-8 font-extrabold mt-10"
          onClick={() => {
            navigate("/wish");
          }}
        >
          Get Started
        </button>
      </section>

      <section className="mb-36 mx-auto">
        <div className="flex justify-center overflow-x-scroll hide-scroll-bar">
          <div className="flex flex-nowrap">
            {Mints.map((mint) => (
              <div className="inline-block px-3" key={mint.id}>
                <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <img
                    src={mint.url}
                    alt={`mint_${mint.id}`}
                    className="w-full h-full object-fill"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="max-w-screen-xxl mx-auto px-20">
        <section className="mb-36">
          <div className="flex items-center gap-x-40">
            <div className="flex-1 items-center justify-center">

	  <img src={videoimg} />
      </div>
            <div className="flex-1">
              <h3 className="text-pink font-extrabold text-sm">
                CREATE INSPIRING NFT ART WITH NFT GENIE
              </h3>
              <h2 className="font-extrabold text-5xl">
                What is NFT Genie?
              </h2>
          <h5 className="text-gray text-lg mt-5">
	  NFT Genie is a unique and innovative AI-powered art generator that offers a utility exclusively for Parasite NFT holders. As a Parasite NFT holder, you can use NFT Genie to create custom digital art pieces simply by entering your text input. NFT Genie is designed to recognize popular NFT collections, making it the perfect tool for Parasite NFT holders looking to create unique and valuable art pieces. In addition, NFT Genie allows you to easily create memes and community art for your favorite NFT projects, making it a versatile tool for Parasite NFT collectors looking to unleash their creativity. With its unparalleled capability and potential, NFT Genie is a must-have tool for Parasite NFT holders looking to create one-of-a-kind art pieces and memes. To access this exclusive utility, you must be a Parasite NFT holder.
              </h5>
            </div>
          </div>
        </section>

      </div>
    </div>
  );
};
export default Home;
