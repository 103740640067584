import { Link } from "react-router-dom";
import { ReactComponent as LogoSvg } from "assets/icons/logo.svg";
import { ReactComponent as DiscordSvg } from "assets/icons/discord.svg";
import { ReactComponent as TwitterSvg } from "assets/icons/twitter.svg";

const Footer = () => {
  return (
    <footer className="h-footer z-50">
      <div className="max-w-screen-xxl mx-auto h-full">
        <div className="flex justify-between px-20 items-center h-full">
          <Link to="/">
            <LogoSvg className="h-25" />
          </Link>
          <div>
            <div className="grid grid-cols-2 grid-rows-3 gap-x-20 gap-y-4 font-bold text-lg">
              <Link to="/wish">Wish</Link>
              <Link to="/your-creations">Your Creations</Link>
              <Link to="/faq">FAQ</Link>
              <Link to="/term-of-service">Term of Service</Link>
            </div>
          </div>
          <div>
            <div className="flex gap-x-10">
              <a
                href="https://discord.com/invite/FuycaCbwyb"
                target="_blank"
                rel="noreferrer"
              >
                <DiscordSvg />
              </a>
              <a
                href="https://twitter.com/NFTGenieAI"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterSvg />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
