import { makeAutoObservable } from "mobx";
import {
  makePersistable,
  clearPersistedStore,
  getPersistedStore,
  isHydrated,
  isPersisting,
} from "mobx-persist-store";

import ApiService from "services/api";
import { sleep } from "utils/connection";
import type RootStore from "./index";

export type NFT = {
  id?: string;
  uri?: string;
  prompt: string;
  name?: string;
  tokenid?: string;
  description?: string;
  website?: string;
  aitype: string;
  network?: string;
  collection?: string;
};
class NFTStore {
  rootStore: RootStore;
  nfts: NFT[] = [];
  isFetchingCreations: boolean = false;
  currentNFT?: NFT = undefined;
  generateNFTStatus?: string = undefined;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: "NFTStore",
      properties: ["nfts"],
      storage: window.localStorage,
    });
  }

  get isHydrated(): boolean {
    return isHydrated(this);
  }

  get isPersisting(): boolean {
    return isPersisting(this);
  }

  async clearPersistedData(): Promise<void> {
    await clearPersistedStore(this);
  }

  reset = () => {
    return this.clearPersistedData();
  };

  async getPersistedData(): Promise<void> {
    await getPersistedStore(this);
  }

  updateGenerateNFTStatus(status?: string) {
    this.generateNFTStatus = status;
  }

  *generateNFT(config: { prompt: string; aitype: string }) {
    this.generateNFTStatus = "processing";
    try {
      // @ts-ignore
      const data = yield ApiService.instance.generateNFTNoWait({
        ...config,
        cognitoid: this.rootStore.authStore.cognitoid || "",
      });
      this.currentNFT = {
        id: data.id,
        uri: `https://s3.amazonaws.com/ai.protogenes/art/${data.id}.png`,
        ...config,
      };
    } catch (e: any) {
      this.generateNFTStatus = "failed";
      throw e;
    }
  }

  *finalizeMint({ txId, mint }: { txId?: string; mint: NFT }) {
    const inst = {
      txid: txId,
      imageid: mint.id,
      name: "NFT Genie Creation",
      description: "",
      website: "",
      network: process.env.REACT_APP_SOLANA_NETWORK!,
    };

    let attemptRetry = 0;
    let data;
    let done = false;
    while (!done && attemptRetry <= 3) {
      try {
        // @ts-ignore
        data = yield ApiService.instance.finalizeMint(inst);
        done = true;
      } catch (err) {
        attemptRetry++;
        yield sleep(attemptRetry * 1000);
        if (attemptRetry === 3) {
          throw err;
        }
      }
    }

    this.currentNFT = {
      ...this.currentNFT,
      ...data,
    };
    this.nfts.push({ ...data, ...mint, network: inst.network });

    return data;
  }

  setCurrentNFT(nft?: NFT) {
    this.currentNFT = nft;
  }

  updateCurrentNFT(nft?: Partial<NFT>) {
    this.currentNFT = {
      ...this.currentNFT,
      ...nft,
    } as NFT;
  }

  *updateNFT(mint: {
    tokenid: string;
    name: string;
    description: string;
    website: string;
    collection?: string;
  }) {
    const data = {
      ...mint,
      collection: mint.collection || "",
      network: process.env.REACT_APP_SOLANA_NETWORK!,
    };
    yield ApiService.instance.updateNFT(data);
    this.updateNFTById(data.tokenid!, {
      name: data.name,
      description: data.description,
      website: data.website,
      network: data.network,
      collection: mint.collection || "",
    });

    // update current NFT
    this.updateCurrentNFT({
      name: data.name,
      description: data.description,
      website: data.website,
      network: data.network,
    });

    // re-get creations in background
    this.getCreations();
  }

  updateNFTById = (tokenid: string, data: Partial<NFT>) => {
    const idx = this.nfts.findIndex((nft) => nft.tokenid === tokenid);
    this.nfts[idx] = { ...this.nfts[idx], ...data };
  };

  *getCreations() {
    try {
      this.isFetchingCreations = true;
      // @ts-ignore
      const data = yield ApiService.instance.getCreations();
      this.nfts = data as any;
    } finally {
      this.isFetchingCreations = false;
    }
  }

  updateNFTByImageId(imageId: string, data: any) {
    const idx = this.nfts.findIndex((nft) => nft.id === imageId);
    this.nfts[idx] = { ...this.nfts[idx], ...data };
  }

  updateCurrentNFTStatus(imageId: string, status: string) {
    console.log("NFTStatus", status);
    if (status === "finished") {
      if (this.currentNFT?.id === imageId) {
        this.generateNFTStatus = "finished";
      }
    }
  }
}

export default NFTStore;
