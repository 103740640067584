import NFTStore from "./nft.store";
import AuthStore from "./auth.store";

class RootStore {
  authStore: AuthStore;
  nftStore: NFTStore;

  constructor() {
    this.authStore = new AuthStore({ rootStore: this });
    this.nftStore = new NFTStore({ rootStore: this });
  }

  getStores = () => {
    return [this.authStore];
  };

  get isStoredSynchronized() {
    return this.getStores().every(Boolean);
  }

  reset() {
    this.getStores().forEach((store) => {
      store.reset();
    });
  }
}

export default RootStore;
