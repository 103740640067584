/* global Paho, SigV4Utils */
import { makeAutoObservable } from "mobx";
import bs58 from "bs58";
import { sign } from "tweetnacl";
import {
  makePersistable,
  clearPersistedStore,
  getPersistedStore,
  isHydrated,
  isPersisting,
} from "mobx-persist-store";
import { PublicKey } from "@solana/web3.js";
import AWS from "aws-sdk";

import ApiService from "services/api";
import type RootStore from "./index";
import { makeId } from "utils/helpers";
import { toast } from "react-toastify";

const stackConfig = {
  up: process.env.REACT_APP_USERPOOL,
  client: process.env.REACT_APP_APPCLIENT,
  ip: process.env.REACT_APP_IDENTITYPOOL,
  poolregion: process.env.REACT_APP_POOLREGION,
};
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: stackConfig.ip!,
});

class AuthStore {
  rootStore: RootStore;
  token?: string = undefined;
  cognitoid?: string = undefined;
  isMqttConnected?: boolean = false;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: "AuthStore",
      properties: ["token", "cognitoid"],
      storage: window.localStorage,
    });
  }

  get isLoggedIn(): boolean {
    return !!this.token && this.token.length > 0;
  }

  get isHydrated(): boolean {
    return isHydrated(this);
  }

  get isPersisting(): boolean {
    return isPersisting(this);
  }

  async clearPersistedData(): Promise<void> {
    await clearPersistedStore(this);
  }

  reset() {
    return this.clearPersistedData();
  }

  async getPersistedData(): Promise<void> {
    await getPersistedStore(this);
  }

  setupCognito() {
    this.updateCredentials();
    this.refreshCognitoCredentials();
  }

  // restoreUserFromStorage() {
  //   this.cognitoUser = userPool.getCurrentUser();
  //   if (this.cognitoUser) {
  //     return new Promise((resolve, reject) => {
  //       this.cognitoUser.getSession((err1, session) => {
  //         if (err1) {
  //           reject(err1);
  //         } else {
  //           console.log(`session validity: ${session.isValid()}`);
  //           this.getAWSCredentials(session, null);
  //           resolve(true);
  //         }
  //       });
  //     });
  //   }
  //   console.log('no currentUser');
  //   return Promise.resolve(null);
  // }

  async refreshCognitoCredentials() {
    if (AWS.config.credentials) {
      await (AWS.config.credentials as AWS.Credentials).refreshPromise();
      setTimeout(
        this.refreshCognitoCredentials,
        (AWS.config.credentials as AWS.Credentials).expireTime.getTime() -
          Date.now()
      );
    }
  }

  updateCredentials = () => {
    (AWS.config.credentials as AWS.Credentials).get((err: any): void => {
      if (err) {
        this.updateCredentials();
        return;
      }

      this.cognitoid = (AWS.config.credentials as any).identityId;
      this.handleMQTT();
    });
  };

  handleMQTT = () => {
    const topic = `ai/${this.cognitoid}/info`;
    // @ts-ignore
    const requestUrl = SigV4Utils.getSignedUrl(
      "wss",
      "a3lvh6w4b5yz3-ats.iot.us-west-2.amazonaws.com",
      "/mqtt",
      "iotdevicegateway",
      "us-west-2",
      (AWS.config.credentials as AWS.Credentials).accessKeyId,
      (AWS.config.credentials as AWS.Credentials).secretAccessKey,
      (AWS.config.credentials as AWS.Credentials).sessionToken
    );
    const that = this;
    // @ts-ignore
    const client = new Paho.Client(requestUrl, makeId(20));
    const connectOptions = {
      onSuccess() {
        console.log("connected mqtt");
        client.subscribe(topic);
      },
      useSSL: true,
      timeout: 10,
      mqttVersion: 4,
      keepAliveInterval: 1200, // max 1200 on AWS IoT
      reconnect: true,
      onFailure(err: any) {
        console.error(`connect failed ${err.errorMessage}`);
        console.log(err);
        // show the error
        toast.error(
          "Can't connect to backend. Please contact #support in our Discord",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        that.isMqttConnected = false;
      },
    };

    client.connect(connectOptions);
    client.onMessageArrived = (message: any) => {
      const msg = JSON.parse(message.payloadString);
      const status = `${msg.msg}`;
      if (status === "status") {
        console.log(msg.jobid, msg.state);
        this.rootStore.nftStore.updateCurrentNFTStatus(msg.jobid, msg.state);
      }
    };
    client.onConnected = () => {
      that.isMqttConnected = true;
    };
    client.onConnectionLost = ({
      errorCode,
      errorMessage,
    }: {
      errorCode: string;
      errorMessage: string;
    }) => {
      console.log("MQTT onConnectionLost");
      console.log("errorCode: ", errorCode);
      console.log("errorMessage: ", errorMessage);
      that.isMqttConnected = false;
    };
  };

  *login({
    publicKey,
    signMessage,
  }: {
    publicKey: PublicKey | null;
    signMessage: ((message: Uint8Array) => Promise<Uint8Array>) | undefined;
  }) {
    const inst = "login()";
    const message = new TextEncoder().encode(inst);

    // Sign the bytes using the wallet
    if (signMessage && publicKey) {
      // @ts-ignore
      const signature = yield signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key

      if (
        publicKey &&
        !sign.detached.verify(message, signature, publicKey.toBytes())
      ) {
        throw new Error("Invalid signature!");
      }

      const instruction = {
        instruction: inst,
        signature: bs58.encode(signature),
        pubkey: publicKey.toBase58(),
      };
      // @ts-ignore
      const data = yield ApiService.instance.login(instruction);

      window.localStorage.setItem("token", data.access_token);
      this.token = data.access_token;
    } else {
      throw new Error("Message signing not supported!");
    }
  }

  async disconnect() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("walletName");
    this.reset();
    this.rootStore.nftStore.reset();
    window.location.reload();
  }
}

export default AuthStore;
