import { ReactNode, useEffect, useRef } from "react";
import NavBar from "containers/navbar";
import Footer from "containers/footer";
import { ReactComponent as OvalSvg } from "assets/icons/oval.svg";
import { ReactComponent as Blur1Svg } from "assets/icons/blur-1.svg";
import { ReactComponent as Blur2Svg } from "assets/icons/blur-2.svg";
import { ReactComponent as Blur3Svg } from "assets/icons/blur-3.svg";
import { observer } from "mobx-react";
import useStores from "hooks/use-stores";
import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-toastify";
import axios from "axios";

type LayoutProps = { children: ReactNode };
const Layout = ({ children }: LayoutProps) => {
  const {
    authStore: { isLoggedIn, login },
    nftStore: {
      getCreations,
      generateNFTStatus,
      currentNFT,
      updateGenerateNFTStatus,
    },
  } = useStores();
  const { publicKey, signMessage } = useWallet();
  const requestTimerRef = useRef<any>(null);
  const timeoutRef = useRef<any>(0);
  const timeoutIdRef = useRef<any>(null);
  const timerController = useRef(new AbortController());

  useEffect(() => {
    async function fetchCreations() {
      try {
        await getCreations();
      } catch (e: any) {
        if (e.response.status === 403) {
          toast.error("Token expired. Please re-approve login and try again.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          login({ publicKey, signMessage });
        }
      }
    }
    if (isLoggedIn) {
      fetchCreations();
    }
  }, [isLoggedIn, getCreations, login, publicKey, signMessage]);

  // Handle workaround for polling generating mint result
  useEffect(() => {
    if (generateNFTStatus === "processing" && !!currentNFT?.id) {
      if (requestTimerRef.current) {
        clearInterval(requestTimerRef.current);
      }
      requestTimerRef.current = setInterval(() => {
        timerController.current.abort();
        timerController.current = new AbortController();

        axios
          .get(
            `https://s3.amazonaws.com/ai.protogenes/art/${currentNFT.id}.png`,
            {
              signal: timerController.current.signal,
            }
          )
          .then(() => {
            updateGenerateNFTStatus("finished");
            clearInterval(requestTimerRef.current);
            requestTimerRef.current = null;
          })
          .catch((err: any) => {
            console.log(err.message);
          });
      }, 10 * 60 * 1000); // 10 minutes
    } else {
      if (requestTimerRef.current) {
        clearInterval(requestTimerRef.current);
        requestTimerRef.current = null;
      }
    }

    return () => {
      if (requestTimerRef.current) {
        clearInterval(requestTimerRef.current);
      }
    };
  }, [generateNFTStatus, currentNFT, updateGenerateNFTStatus]);

  useEffect(() => {
    if (generateNFTStatus === "processing") {
      if (!timeoutIdRef.current) {
        timeoutIdRef.current = setInterval(() => {
          timeoutRef.current = timeoutRef.current + 1000; // + 1000 milliseconds

          if (timeoutRef.current > 2 * 60 * 60 * 1000) {
            // 2 hours
            timeoutRef.current = 0;
            clearInterval(timeoutIdRef.current);
            timeoutIdRef.current = null;
            toast.error(
              "We are sorry but something went wrong here. Please try again or contact #support in our Discord",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }, 1000);
      }
    } else {
      timeoutRef.current = 0;
      if (timeoutIdRef.current) {
        clearInterval(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    }

    return () => {
      if (timeoutIdRef.current) {
        clearInterval(timeoutIdRef.current);
      }
    };
  }, [generateNFTStatus]);

  return (
    <div className="relative">
      <OvalSvg className="absolute top-40 -z-10" />
      <Blur1Svg className="absolute left-0 top-1/4 h-[40rem] -z-10" />
      <Blur2Svg className="absolute right-0 top-1/4 h-[30rem] -z-10" />
      <Blur3Svg className="absolute bottom-0 left-0 right-0 h-[30rem] w-full -z-10" />
      <NavBar />
      <div className="min-h-body z-50">{children}</div>
      <Footer />
    </div>
  );
};

export default observer(Layout);
